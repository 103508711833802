import { singleton } from "@solvari/common-fe/helpers";
import dayjs from "dayjs";
import calendar from "dayjs/plugin/calendar";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";
import utc from "dayjs/plugin/utc";

import { getEnv } from "./solvariConfig";

import "dayjs/locale/fr";
import "dayjs/locale/nl";

const dayjsInstance = singleton(() => {
  dayjs.extend(relativeTime);
  dayjs.extend(calendar);
  dayjs.extend(updateLocale);
  dayjs.extend(utc);
  dayjs.extend(LocalizedFormat);

  dayjs.locale(getEnv().config.lang);

  dayjs.updateLocale("nl", {
    calendar: {
      lastDay: "[Gisteren]",
      sameDay: "[Vandaag]",
      nextDay: "[Morgen]",
      lastWeek: "dd D MMMM",
      nextWeek: "dd D MMMM",
      sameElse: "dd D MMMM",
    },
  });

  dayjs.updateLocale("fr", {
    calendar: {
      lastDay: "[Hier]",
      sameDay: "[Aujourd'hui]",
      nextDay: "[Demain]",
      lastWeek: "dd D MMMM",
      nextWeek: "dd D MMMM",
      sameElse: "dd D MMMM",
    },
  });

  return dayjs;
});

const sDayjs = (date?: dayjs.ConfigType) => dayjsInstance()(date);

export default sDayjs;
